import {useState} from "react";
import Swal from 'sweetalert2'
import axios from "axios";

export function LinkShortener({active}) {
    const [link, setLink] = useState("")

    const submit = () => {
        if (link.replaceAll(" ", "") === "") return
        axios.post("/shortLink", {link}).then(result => {
            const id = result.data.id
            const link = location.href + id
            navigator.clipboard.writeText(link)
            Swal.fire({
                title: "Link erstellt!",
                icon: "success",
                html: `<a href='${link}'>${link}</a>`
            })
            setLink("")
        }).catch(() => {
            Swal.fire({
                title: "Fehler",
                icon: "error",
                text: "Es ist ein Fehler aufgetreten bitte versuche es erneut"
            })
        })
    }

    return (
        <div className={`linkfield ${active ? "active" : ""}`}>
            <input type="text" className={"stf"} value={link} onChange={event => setLink(event.target.value)}/>
            <button className={"sbt"} onClick={submit}>Short</button>
        </div>
    )
}
