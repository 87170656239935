import {useRef, useState} from "react";
import Swal from "sweetalert2";
import axios from "axios";

export function SelectButtons({active, setActive}) {
    const inputRef = useRef()

    const onFileChanged = (event) => {
        const file = event.target.files[0]
        if (!file) return
        //Check file size
        if (file.size > 20_971_520) {
            Swal.fire({
                title: "Bild ist zu groß",
                icon: "error",
                text: "Das Bild darf maximal 20 MB groß sein"
            })
            return;
        }
        //Check if file is image
        if (!file.type.startsWith("image/")) {
            Swal.fire({
                title: "Kein Bild",
                icon: "error",
                text: "Es sind nur Bilder erlaubt."
            })
        }
        //Send to server
        const formData = new FormData()
        formData.set("file", file)
        axios.post("/imageLink", formData).then(result => {
            //Success
            const id = result.data.id
            const link = location.href + id
            navigator.clipboard.writeText(link)
            Swal.fire({
                title: "Bild hochgeladen",
                icon: "success",
                html: `<a href='${link}'>${link}</a>`
            })
            inputRef.current.value = null
        }).catch(() => {
            //Failed
            Swal.fire({
                title: "Fehler",
                icon: "error",
                text: "Es ist ein Fehler aufgetreten bitte versuche es erneut"
            })
        })
    }

    return (
        <div className={`buttons ${active ? "active" : ""}`}>
            <input type="file" accept="image/*" ref={inputRef} onChange={onFileChanged}/>
            <button className="ul link" style={{marginRight: "8%"}} onClick={() => setActive(!active)}><i className="fa-solid fa-link"/> Link verkürzen</button>
            <button className="ul image" style={{marginLeft: "8%"}} onClick={() => inputRef.current.click()}><i className="fa-solid fa-image"/> Bild hochladen</button>
        </div>
    )
}
