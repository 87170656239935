import './App.scss';
import {LinkShortener} from "./LinkShortener";
import {SelectButtons} from "./SelectButtons";
import {useState} from "react";

export function App() {
    const [active, setActive] = useState(false)

    return (
        <div className="App">
            <div id="headline" className={`${active ? "active" : ""}`}>
                <h1>Dicker Upload</h1>
            </div>
            <SelectButtons active={active} setActive={setActive}/>
            <LinkShortener  active={active} setActive={setActive}/>
        </div>
    );
}
